// @ts-nocheck
import Divider from '../Divider/Divider';
import { CATEGORIES } from '../../constants/categories';
import { useEffect } from 'react';

function Sidebar({ selectedCategory, setCategoryHandler, setModal, closeModal }) {
  const sidebarClicked = (category) => {
    closeModal()
    setCategoryHandler(category)
  }

  const emailAddress = "laueliz1@hotmail.co.uk"
  const emailSubject = "Contact"
  const mailLocation = `mailto:${emailAddress}?subject=${emailSubject}`

  const title = "hi! i'm lauren :)"
  const description = `I currently work for the University of Warwick as part of their creative studio, designing, animating, illustrating, and copywriting for a wide student and academic audience. I am a highly motivated self-starter, interested in and passionate about nature and all things creative.`
  const titleTypingSpeed = 50

  let handle;
  const replaceText = () => {
    try {
    var h1 = document.getElementById("about-title")
    var spanClone = document.getElementById("title-span")?.cloneNode()

    h1.innerHTML = ""
    h1?.appendChild(spanClone)
    } catch {
      clearInterval(handle)
    }
  }

  const typeText = async () => {
    try {
      replaceText()
      let titleElem = document.getElementById("title-span")
      titleElem.style.display = "inline"

      for (const char of title) {
        titleElem?.insertAdjacentHTML("beforebegin", char)
        await new Promise(resolve => setTimeout(resolve, titleTypingSpeed));
      }
      await new Promise(resolve => setTimeout(resolve, 500));
      titleElem.style.display = "none"
    } catch {
      clearInterval(handle)
    }
  }

  useEffect(() => {
    typeText()
    try {
      handle = setInterval(
        typeText.bind(),
        15000
      )
    } catch {
      clearInterval(handle)
    }
  }, [])

  return (
    <nav className='sticky top-0 flex flex-col self-start w-1/5 h-screen text-white text-left bg-black'>
      <div className='mt-8 mx-8'>
        <div id="about-div" className='mb-6 text-6xl font-extrabold leading-none tracking-tight h-48 sm:text-4xl lg:text-6xl'>
            <h1 id="about-title">
              <span id='title-span' className='animate-blink'>   |</span>
            </h1>
        </div>

      <div className='cursor-pointer text-xl mt-16'>
          <div className='flex flex-col' onClick={ () => {
              window.location.href = mailLocation
            }}>
            <div className="group relative w-max" >
              <h3>contact</h3>
              <span
                className=" text-black pointer-events-none p-4 rounded-md bg-slate-200 absolute -top-4 left-24 w-max opacity-0 transition-opacity group-hover:opacity-100"
              >
                {emailAddress}
              </span>
            </div>
          </div>

          <a href="https://www.instagram.com/shrubsyart/" target="_blank">
            <span>
              instagram
            </span>
          </a>

          <div className="flex justify-center">
              <hr className={`h-px w-3/4 -ml-8 my-8 bg-gray-200 opacity-40 border-0`}/>
          </div>

          <div className='flex flex-col -mr-8'>
            <h3 className={selectedCategory === CATEGORIES.ALL ? ' bg-slate-400 text-black' : ''} onClick={() => sidebarClicked(CATEGORIES.ALL)}>all</h3>
            {/* <h3 className={selectedCategory === CATEGORIES.UOW ? 'bg-slate-400 text-black' : ''} onClick={() => sidebarClicked(CATEGORIES.UOW)}>uni of warwick</h3> */}
            <h3 className={selectedCategory === CATEGORIES.DESIGN ? 'bg-slate-400 text-black' : ''} onClick={() => sidebarClicked(CATEGORIES.DESIGN)}>design</h3>
            <h3 className={selectedCategory === CATEGORIES.ANIMATION ? 'bg-slate-400 text-black' : ''} onClick={() => sidebarClicked(CATEGORIES.ANIMATION)}>animation</h3>
            <h3 className={selectedCategory === CATEGORIES.ILLUSTRATION ? 'bg-slate-400 text-black' : ''} onClick={() => sidebarClicked(CATEGORIES.ILLUSTRATION)}>illustration</h3>
            {/* <h3 className={selectedCategory === CATEGORIES.GRAPHIC_DESIGN ? 'text-blue-400 py-1' : 'py-1'} onClick={() => sidebarClicked(CATEGORIES.GRAPHIC_DESIGN)}>graphic design</h3> */}
            {/* <h3 className={selectedCategory === CATEGORIES.PERSONAL ? 'text-blue-400 py-1' : 'py-1'} onClick={() => sidebarClicked(CATEGORIES.PERSONAL)}>personal</h3> */}
          </div>
        </div>
      </div>


      <span className='text-2xl mt-auto ml-8 mb-8 mr-8 font-semibold'>
          <strong>Solving problems & storytelling with creative, impactful design.</strong>
        </span>
      {/* <span className='mt-auto ml-8 mb-8 font-semibold sm:text-2xl lg:text-4xl'>
        designer/ <br/>
        artist/ <br/>
        creative <br/>
      </span> */}

    </nav>
  );
}

export default Sidebar